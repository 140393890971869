import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-o-jogo-do-padrão",
      "style": {
        "position": "relative"
      }
    }}>{`🌈 O jogo do padrão`}<a parentName="h1" {...{
        "href": "#-o-jogo-do-padr%C3%A3o",
        "aria-label": " o jogo do padrão permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <StatusBanner warning mdxType="StatusBanner">
      <p>{`O Kernel não dá respostas concretas. Estamos aqui para fazer perguntas melhores.`}</p>
    </StatusBanner>
    <p>{`Isso acontece porque não há respostas simples e únicas para as perguntas que estamos interessados em aprender e ensinar. Isso é o que as torna boas perguntas.`}</p>
    <p><strong parentName="p">{`Nossa intenção é te fornecer a estrutura para começar a pensar de maneira transformadora sobre as questões mais importantes levantadas pelo mundo da Web 3 que esstamos construindo juntos.`}</strong></p>
    <p>{`O destaque da pessoa que entende e aplica o Kernel é a sua habilidade de `}<strong parentName="p">{`identificar padrões`}</strong>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/mod0-img/banner.jpg",
        "alt": "Banner"
      }}></img></p>
    <p>{`O reconhecimento de padrões é central para a cognição. Tornar-se mais consciente dos padrões que já existem, e também de como você escolhe interagir com eles em seu ambiente, é a melhor coisa que você pode fazer para melhorar suas habilidades de pensamento crítico.`}</p>
    <p>{`Em particular, esperamos que nossos Colegas de Kernel comecem a pensar em termos de `}<strong parentName="p">{`opostos complementares.`}</strong></p>
    <p>{`Ao invés de usar dualidades como 'a descentralização é boa e a centralização é ruim'; ou 'DeFi é inovador, bancos são chatos', essa linha de pensamento reconhece que não há bem sem mal; não há atração sem repulsão, não há Norte sem Sul, nem em cima sem embaixo.`}</p>
    <p>{`Poderíamos descrever isso como "pensamento quântico": Ser capaz de contemplar 0 e 1 simultaneamente, bem como o espectro de probabilidade entre eles. Se você consegue cultivar honestamente a humildade, poderá aprender como manter todos os três sobrepostos sem qualquer apego à certeza e, assim, tomar decisões mais equilibradas.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Pergunta: O Pensamento Quântico se propõe a identificar a interação de que tipo de padrão?`}</p>
        <p>{`Opostos Complementares.`}</p>
      </Card>
    </Flash>
    <p>{`Colegas Kernel `}<strong parentName="p">{`devem ser humildes.`}</strong>{` Não podemos ter certeza de qual será o efeito das nossas ações, podemos apenas cultivar uma percepção aguçada que nos guiará `}<em parentName="p">{`rumo decisões equilibradas.`}</em></p>
    <p>{`Se você quer mudar o mundo, o mundo inevitavelmente reagirá e mudará você. Os resultados dos aplicativos da Web2 deixaram isso claro. Quanto maior for nossa humildade coletiva, mais gentil será essa passage para a Web3.`}</p>
    <p>{`A ideia principal aqui é que refletir com consciência sobre os prós e contras, desenvolvendo a capacidade de manter muitas possibilidades diferentes em mente, simultaneamente, sem permitir que vieses pessoais obscureçam sua visão de todos os resultados possíveis, permitindo assim que você escolha um meio termo com alguma frequência.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Pergunta: `}<strong parentName="p">{`Qual característica nos influência a tomar decisões mais construtivas?`}</strong></p>
        <p><strong parentName="p">{`Humildade`}</strong></p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "a-pergunta-honesta",
      "style": {
        "position": "relative"
      }
    }}>{`A Pergunta Honesta`}<a parentName="h3" {...{
        "href": "#a-pergunta-honesta",
        "aria-label": "a pergunta honesta permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Aqueles que mudaram positivamente o mundo, o fizeram porque aprenderam a negociar a complexidade ao invés de impor sua própria vontade sobre as coisas. Eles responderam às suas próprias perguntas da maneira mais honesta e direta possível.`}</p>
    <p>{`Brincar com os padrões que se repetem e tornar-se consciente da interação constante de opostos complementares como meio de cultivar a presença nos leva a uma pergunta simples:`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 Para qual pergunta o universo diria que a resposta é você?`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`💡 Como você pode responder com mais honestidade, precisão e amor?`}</p>
    </blockquote>
    <h3 {...{
      "id": "adivinhação-nossa-humanidade",
      "style": {
        "position": "relative"
      }
    }}>{`Adivinhação Nossa Humanidade`}<a parentName="h3" {...{
        "href": "#adivinha%C3%A7%C3%A3o-nossa-humanidade",
        "aria-label": "adivinhação nossa humanidade permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <List mdxType="List">
      <Link to="https://www.youtube.com/watch?v=xmlo0Lva5AU" mdxType="Link">
        <p>{`"Qual destes mais representa o seu verdadeiro eu?"`}</p>
      </Link>
      <Link to="https://thehumandivine.org/2021/02/07/fearful-symmetry-william-blake-and-sacred-geometry-by-rod-tweedy/amp/" mdxType="Link">
        <p>{`"O livro da natureza está escrito na linguagem dos entremeios"`}</p>
      </Link>
      <Link to="https://terebess.hu/zen/mesterek/Branching-Streams.pdf" mdxType="Link">
        <p>{`"A maneira de resolver é caminhar e caminhar, sem parar."`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      